<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            class="align-text-top"
            icon="HomeIcon"
            size="15"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t('label_deals_without_action') }}
        </b-breadcrumb-item>
      </div>
    </div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button size="sm" @click="refreshDealsWithoutActivity" variant="primary" v-if="['admin', 'superadmin'].includes(userData.role)">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <v-select
              v-model="tableData.perPage"
              :searchable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tableData.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <column-chooser
              v-model="selectedColumns"
              :not-touch="['id']"
              :columns="columnsDefs"
            />

          </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

      <!--sticky-header-->
      <div class="px-2 table-container-wrap">
        <b-table
          ref="table"
          class="position-relative transited-table"
          :busy="isBusy"
          no-border-collapse
          :items="filtredItems"
          responsive
          :fields="selectedColumns"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
        >

          <template #head()="data">
            <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
          </template>

          <template #cell(consultant)="data">
            <router-link
                v-if="data.item.consultant && $can('view', 'consultant.tab_details')"
                :to="{ name: 'consultant-details', params: { id: data.item.consultant.id } }"
            >
              {{ data.item.consultant? data.item.consultant.name : '--' }}
            </router-link>
            <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
          </template>

          <template #cell(firm_name)="data">
            <router-link
              v-if="$can('view', 'contact.tab_details') && data.item.contact_id"
              target="_blank"
              :to="{ name: 'contact-details', params: { id: data.item.contact_id } }"
            >
              <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </router-link>
            <template v-else>
              <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status_id && agreement_statuses.findWhere('id', data.item.status_id)"
              pill
              :variant="`light-${resolveAgreementStatusVariant(agreement_statuses.findWhere('id', data.item.status_id)?agreement_statuses.findWhere('id', data.item.status_id).name_alias : '', true)}`"
              class="text-capitalize"
            >
              {{ $t(`${agreement_statuses.findWhere('id', data.item.status_id).translation_index}`) || '--' }}
            </b-badge>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(note)="{ value, item }">
            <b-badge
              v-if="value && value.compelling_role"
              pill
              variant="primary"
              class="text-capitalize"
            >
              {{ $t(`label_${value.compelling_role}`) }}
            </b-badge>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(deal_number)="data">

            <div style="min-width: 110px">
              <router-link
                v-if="$can('view', 'deal.tab_details')"
                target="_blank"
                :to="{ name: 'deal-details', params: { id: data.item.deal.id } }"
              >
                {{ data.item.deal_number }}
              </router-link>
              <template v-else>
                {{ data.item.deal_number }}
              </template>
            </div>
          </template>
          <!-- Column: Actions -->
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableTotal"
                :per-page="tableData.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

      </div>


    </b-card>

  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import infinityScroll from '@/views/components/infinityScroll'

import columnChooser from '@/views/components/columnChooser'
import {resolveAgreementStatusVariant} from "@/views/pages/deal/moduleHelper";
import axios from "axios";
import {$themeConfig} from "../../../../themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    columnChooser,
    infinityScroll
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      editedItem: false,
      isSidebarOpen: false,

      selectedColumns:[],

      columnsDefs: [
        {label: 'label_deal_number', key: 'deal_number', sortable: false},
        {label: 'label_firm_name', key: 'firm_name', sortable: false},
        {label: 'label_consultant_leading_case', key: 'consultant', sortable: false},
        {
label: 'label_last_date_of_activity',
key: 'activ_date',
sortable: false,
formatter: function (v) {
            return formatDate(v, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm');
          }
},
        {label: 'label_status', key: 'status', sortable: false},
        {label: 'label_compelling_role', key: 'note', sortable: false},
      ],

      isBusy:false,
      tableTotal: 0,
      tableItems: [],

      tableData: {
        currentPage: 1,
        perPage: 25,
        searchQuery: '',
        sortBy: 'id',
        sortDesc: true,
        perPageOptions: [10, 25, 50, 100],
      },

      filterData: {
        paymentFromAt:null,
        paymentToAt:null,
        paymentStatusFilter: null,
        dateFromFilter: null,
        dateToFilter: null,
        consultantFilter: null,
        agentFilter: null,
        statusFilter: null,

      },

      agreement_statuses:[],

      payment_statuses:[]
    }
  },
  computed: {
    dataMeta: (context) => ({
        from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
        to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
        of: context.tableTotal,
      }),
    filtredItems() {
      return this.tableItems?.filter(row => !['196', '197', '199'].includes(row?.status_id))
    }
  },
  watch: {
    tableData: {
      handler: function (newVal, old) {
        let self = this;
        self.refreshTableId = setTimeout(function(){
          clearInterval(self.refreshTableId);
          self.refreshDataTable();
        }, 100);
      },
      deep: true
    },
    filterData: {
      handler: function (old, newVal) {
        this.refreshDataTable();
      },
      deep: true
    }

  },

  created() {
    this.async('get', '/select_options', {params:{options:['agreement_statuses']}}, function(res){
      // this.taskTypes = res.data.agrm_task_types;
      this.agreement_statuses = res.data.agreement_statuses;
    });
    this.refreshDataTable();

  },
  methods: {
    resolveAgreementStatusVariant,
    async refreshDealsWithoutActivity() {
      this.isBusy = true
      try {
        await axios.get($themeConfig.app.domain + 'cron/Agreements_cron/activity')
      } finally {
        this.refreshDataTable()
        this.isBusy = false
      }
    },
    refreshDataTable: function () {
      this.isBusy = true;
      this.async('get', `/deal/without_activity`, {
        params: {
          // contact_id: this.moduleItem.id,
          length: this.tableData.perPage,
          start: (this.tableData.currentPage - 1) * this.tableData.perPage,
          search: this.tableData.searchQuery,
          sort_by: this.tableData.sortBy,
          sort_desc: null,
        }
      }, function (resp) {
        this.tableItems = resp.data.items?.map(agreementItem => ({
          agreement_id: agreementItem.agreement_id,
          activ_date: agreementItem.date,
          ...agreementItem.agreement,
          note: agreementItem.note,
        }));
        this.tableTotal = resp.data.total;
        this.isBusy = false;
      });
    },
  },

}
</script>

